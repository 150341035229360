import React, { useContext, useMemo, useState } from 'react';
import { IoAdd, IoClose, IoRemove } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { ArticleContext } from '../../contexts/ArticleContext';
import { ShopContext } from '../../contexts/ShopContext';
import categories from '../data/categories';
import { FaArrowAltCircleRight } from 'react-icons/fa';

const SideBar = ({ isSideBarOpen, handleSideBar, isVisible, setIsVisible, handleSubCategoryChange }) => {

  const { shops } = useContext(ShopContext);
  const { articles } = useContext(ArticleContext);
  const getRandomShops = (shopsArray, count) => {
    const shuffled = [...shopsArray].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const filteredShops = useMemo(() => {
    if (!shops || shops.length === 0) return [];

    let filtered = [];

    filtered = getRandomShops(shops);

    return filtered;
  }, [shops]);

  console.log(filteredShops)

  return (
    <>
      <div className={isSideBarOpen ? "sidebar  has-scrollbar active" : "sidebar  has-scrollbar"} data-mobile-menu="">
        <div className="sidebar-category">
          <div className="sidebar-top">
            <h2 className="sidebar-title">Catégories</h2>
            <button
              className="sidebar-close-btn"
              data-mobile-menu-close-btn=""
              onClick={handleSideBar}
            >
              <IoClose />
            </button>
          </div>
          <ul className="sidebar-menu-category-list">
            {categories.map((categorie) => (
              <li className="sidebar-menu-category">
                <button
                  className="sidebar-accordion-menu"
                  data-accordion-btn="" onClick={() => {
                    if (isVisible === `${categorie.name}`) {
                      setIsVisible('')
                    } else (
                      setIsVisible(`${categorie.name}`)
                    )
                  }}

                >
                  <div className="menu-title-flex">
                    <p className="menu-title">{categorie.name}</p>
                  </div>
                  <div>
                    <IoAdd className={isVisible === `${categorie.name}` ? 'hidden' : 'block'} />
                    <IoRemove className={isVisible === `${categorie.name}` ? 'block' : 'hidden'} />
                  </div>
                </button>
                <ul className={isVisible === `${categorie.name}` ? "sidebar-submenu-category-list active" : "sidebar-submenu-category-list"} >
                  {categorie.subCategories.map((subCategorie) => (
                    <li className="sidebar-submenu-category">
                      <Link className="sidebar-submenu-title" onClick={() => {
                        handleSubCategoryChange(`${subCategorie.name}`, `${categorie.name}`)
                        window.scrollTo(0, 0);
                      }}>
                        <p className="product-name">{subCategorie.name}</p>
                        <data
                          value={45}
                          className="stock"
                          title="Available Stock"
                        >
                          {articles.filter(article =>
                            article.category === categorie.name && article.subCategory === subCategorie.name
                          ).length}
                        </data>
                      </Link>
                    </li>
                  ))

                  }
                </ul>
              </li>
            ))
            }
          </ul>

        </div>
        <div className="product-showcase">
          <h3 className="showcase-heading">Visiter nos établissements</h3>
          <div className="showcase-wrapper">
            <div className="showcase-container">
              {filteredShops.slice(0, 4).map((shop, index) => (
                <>
                  <div index={shop._id} className="showcase">
                    <Link className="showcase-img-box" to={`/shop/${shop._id}`}>
                      <img
                        src={shop.profilePic || "/images/logo_minimalist.png"}
                        alt={shop.name}
                        width={75}
                        height={75}
                        className={"showcase-img w-[60px] h-[60px]"}
                      />

                    </Link>
                    <div className="showcase-content">
                      <Link to={`/shop/${shop._id}`}>
                        <h4 className="showcase-title">{shop.name}</h4>
                      </Link>
                    </div>
                  </div>
                </>
              ))
              }
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;