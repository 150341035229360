import React from 'react';
import { Link } from 'react-router-dom';

const CategoryBox = () => {
  return (
    <>
      {/*BLOG*/}
      <div className="blog">
        <div className="container">
          <div className="blog-container has-scrollbar ">
            <div className="blog-card">
              <Link>
                <img
                  src="/images/homme.jpg"
                  alt="Clothes Retail KPIs 2021 Guide for Clothes Executives"
                  
                  className="blog-banner w-[100%] h-[175px] object-cover"
                />
              </Link>
              <div className="blog-content">
                <Link className="blog-category">
                  HOMME
                </Link>
                
              </div>
            </div>
            <div className="blog-card">
              <Link>
                <img
                  src="/images/femme.jpg"
                  alt="Curbside fashion Trends: How to Win the Pickup Battle."
                  className="blog-banner w-[100%] h-[175px] object-cover"
                  width={300}
                  height={200}
                />
              </Link>
              <div className="blog-content">
                <Link className="blog-category">
                  FEMME
                </Link>
                
              </div>
            </div>
            <div className="blog-card">
              <Link>
                <img
                  src="/images/devices.jpg"
                  alt="EBT vendors: Claim Your Share of SNAP Online Revenue."
                  className="blog-banner w-[100%] h-[175px] object-cover"
                  width={300}
                  height={200}
                />
              </Link>
              <div className="blog-content">
                <Link className="blog-category">
                  TELEPHONE ET TABLETTE
                </Link>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryBox;