import React, { useState } from 'react';
import Navigation from '../components/home/Navigation';
import Footer from '../components/home/Footer';
import Boutique from '../components/home/Boutique';

const StorePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState();
  const [isSideBarOpen, setIsSideBarOpen] = useState();

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const handleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen)
  }
  return (
    <>
      <Navigation 
      handleMenu={handleMenu}
      handleSideBar={handleSideBar}
      isMenuOpen={isMenuOpen}
        isSideBarOpen={isSideBarOpen}
      />
      <Boutique />
      <Footer />
    </>
  );
};

export default StorePage;