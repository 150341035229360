import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Carousel = () => {
  return (
    <>
      {/* BANNER*/}

      <div className="banner">
        <div className="container">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className=""
          >
            <SwiperSlide>
              <div className="slider-item">
                <img
                  src="/templates/banner-2.jpg"
                  alt="modern sunglasses"
                  className="banner-img"
                />
                <div className="banner-content">
                  <p className="banner-subtitle">Tous vos accessoires de mode</p>
                  <h2 className="banner-title">Des paires de lunettes moderne</h2>
                  <Link className="banner-btn">
                    Achetez maintenant
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-item">
                <img
                  src="/templates/banner-3.jpg"
                  alt="new fashion summer sale"
                  className="banner-img"
                />
                <div className="banner-content">
                  <p className="banner-subtitle">Reduction sur les articles</p>
                  <h2 className="banner-title">De nouveaux vêtements pour l'été</h2>

                  <Link className="banner-btn">
                    Achetez maintenant
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Carousel;
